import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { AppEventsContext } from '../contexts/AppEventsContext'

interface UseArticleViewedOpts {
  articleId: string | null
  url: string
  categoryLevel: number
  categoryTitle: string
  author: string
  title: string
  wordCount: number
  // f.e. 2024-04-12T00:00:00.000Z,
  date: string
}

export function useArticleViewed(opts: UseArticleViewedOpts): (node?: Element | null) => void {
  const { appEvent } = AppEventsContext.useContainer()
  const { ref, inView } = useInView({ triggerOnce: true })

  useEffect(() => {
    if (inView) {
      appEvent({
        event: 'page-article-viewed',
        ...opts,
      })
    }
  }, [inView])

  return ref
}
